export const districtNames = new Map([
  [1, 'Средец'],
  [2, 'Изгрев'],
  [3, 'Лозенец'],
  [4, 'Триадица'],
  [5, 'Витоша'],
  [6, 'Панчарево'],
  [7, 'Овча купел'],
  [8, 'Красна поляна'],
  [9, 'Сердика'],
  [10, 'Илинден'],
  [11, 'Надежда'],
  [12, 'Нови Искър'],
  [13, 'Връбница'],
  [14, 'Люлин'],
  [15, 'Банкя'],
  [16, 'Красно село'],
  [17, 'Възраждане'],
  [18, 'Оборище'],
  [19, 'Слатина'],
  [20, 'Искър'],
  [21, 'Младост'],
  [22, 'Кремиковци'],
  [23, 'Подуяне'],
  [24, 'Студентски'],
]);

export const districtOptions = [
  { value: 'Средец', label: 'Средец' },
  { value: 'Изгрев', label: 'Изгрев' },
  { value: 'Лозенец', label: 'Лозенец' },
  { value: 'Триадица', label: 'Триадица' },
  { value: 'Витоша', label: 'Витоша' },
  { value: 'Панчарево', label: 'Панчарево' },
  { value: 'Овча купел', label: 'Овча купел' },
  { value: 'Красна поляна', label: 'Красна поляна' },
  { value: 'Сердика', label: 'Сердика' },
  { value: 'Искър', label: 'Искър' },
  { value: 'Надежда', label: 'Надежда' },
  { value: 'Искър', label: 'Искър' },
  { value: 'Връбница', label: 'Връбница' },
  { value: 'Люлин', label: 'Люлин' },
  { value: 'Банкя', label: 'Банкя' },
  { value: 'Красно село', label: 'Красно село' },
  { value: 'Възраждане', label: 'Възраждане' },
  { value: 'Оборище', label: 'Оборище' },
  { value: 'Слатина', label: 'Слатина' },
  { value: 'Илинден', label: 'Илинден' },
  { value: 'Младост', label: 'Младост' },
  { value: 'Кремиковци', label: 'Кремиковци' },
  { value: 'Подуяне', label: 'Подуяне' },
  { value: 'Студентски', label: 'Студентски' },
];
