export const topics = [
  { value: 'Град за хората', label: 'Град за хората' },
  { value: 'Проекти и реформи', label: 'Проекти и реформи' },
  { value: 'Образование и култура', label: 'Образование и култура' },
  { value: 'Зеленина и паркове', label: 'Зеленина и паркове' },
  { value: 'Акции', label: 'Акции' },
  { value: 'Паркиране', label: 'Паркиране' },
  { value: 'Спортни и детски площадки', label: 'Спортни и детски площадки' },
  { value: 'Транспорт и инфраструктура', label: 'Транспорт и инфраструктура' },
];

// [
//   { value: 'Градска среда - Чист и зелен град', label: 'Чист и зелен град' },
//   { value: 'Градска среда - Иновации', label: 'Иновации' },
//   { value: 'Градска среда - Инфраструктура', label: 'Инфраструктура' },
//   { value: 'Градска среда - Жилищна политика', label: 'Жилищна политика' },
//   { value: 'Социална политика - Образование', label: 'Образование' },
//   { value: 'Социална политика - Здраве и спорт', label: 'Здраве и спорт' },
//   {
//     value: 'Транспортна инфраструктура - Градски транспорт',
//     label: 'Градски транспорт',
//   },
//   {
//     value: 'Транспортна инфраструктура - Паркинги и гаражи',
//     label: 'Паркинги и гаражи',
//   },
//   { value: 'Транспортна инфраструктура - Ремонти', label: 'Ремонти' },
//   { value: 'Администрация - Бюджет и финанси', label: 'Бюджет и финанси' },
//   { value: 'Бизнес и туризъм', label: 'Бизнес и туризъм' },
//   {
//     value: 'Култура и историческо наследство',
//     label: 'Култура и историческо наследство',
//   },
//   { value: 'Събития - Акции', label: 'Акции' },
//   { value: 'Събития - Срещи', label: 'Срещи' },
// ];
